import React from "react";
import { FaqQuestion } from "./FaqQuestion";

export const Faq = () => {
  return (
    <div className="Faq common_width">
      <h1>Frequently asked questions</h1>
      <FaqQuestion
        heading="Is there any fee for merchants to join the network?"
        ans="Inburg is a premium merchant network. Joining is free, but your subscription plan determines
how often your store and coupons are shown to customers."
      />
      <FaqQuestion
        heading="How are coupons created?"
        ans="When you sign up as a merchant, you provide your store name, the number of stores, and the
product categories you deal with. You then set the discount coupon denominations based on
sub-categories. Finally, Inburg manages the creation, updating, and deletion of coupons on your
behalf."
      />
      <FaqQuestion
        heading="How many times my store coupons are shown to customers?"
        ans="This depends on multiple factors, with two key ones being:<br />
● Your subscription plan<br />
● Positive signals"
      />
      <FaqQuestion
        heading="What are positive signals?"
        ans="Positive signals are factors our proprietary algorithm considers, such as:<br />
● Coupon generation rate<br />
● Coupon acceptance rate<br />
● Number of times Inburg’s QR code is scanned"
      />

      <FaqQuestion
        heading="How do you rank brands/coupons of the same categories? "
        ans="Ranking is based on multiple factors, with two key ones being:<br />
● Your subscription plan<br />
● Positive signals"
      />

      <FaqQuestion
        heading="Can we be transparent about any charges involved?"
        ans="Yes. Apart from the optional monthly subscription fee (you can remain a free merchant forever),
we charge a small sales convenience fee on each successful coupon redemption. Please check
the pricing section for details."
      />
      <p className="faq_learn_more">
      Got more questions? Feel free to reach out at <a href="mailto:hello@inburg.com">hello@inburg.com</a>
      </p>
    </div>

  );
};
