import React, { useState } from "react";
import plus from "../assets/img/plus.svg";
import minus from "../assets/img/minus.svg";

export const FaqQuestion = ({ heading, ans }) => {
  const [active, setactive] = useState(false);
  return (
    <div className="FaqQuestion">
      <div
        className="head"
        onClick={(e) => {
          setactive(!active);
        }}
      >
        <h1>{heading}</h1>
        {active ? <img src={minus} alt="" /> : <img src={plus} alt="" />}
      </div>
      {active && <p dangerouslySetInnerHTML={{__html: ans }} />}
    </div>
  );
};
